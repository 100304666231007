<template>
  <main class="df-c flex-column w-100">
    <h2 class="color-green4 my10">Order Approved</h2>
    <img src="/assets/img/icons/check-circle-light-green.svg" alt="">
    <h3 class="my10">Order Num: 5643213256</h3>
    <p>Track your order status in</p>
    <h3 class="color-blue11">Your personal zone</h3>
    <p>* First time credentials</p>
    <p><b>User Name: </b>0543095860</p>
    <p><b>Password: </b>zxcg3242sa</p>
    <p class="m-t-20">Send a copy to your email</p>
    <input 
      type="text" 
      class="input w-75"
      placeholder="Enter email"
    >
    <button class="primary-btn bg-green6 primary-btn_sm m-t-15">
      Send
    </button>
  </main>
</template>

<script>
export default {
  name: 'OrderApproved'
}
</script>

<style lang="scss" scoped>
  p {
    font-size: 18px;
    margin: 5px 0;
  }
</style>